import { Link } from "./styles";
import { ResultProps } from "./types";

export const Result = ({ result }: ResultProps) => {
  if (result.includes("http")) {
    return (
      <Link target="_blank" href={result}>
        {result}
      </Link>
    );
  } else if (result.includes("@gmail.com")) {
    return (
      <Link target="_blank" href={`mailto:${result}`}>
        {result}
      </Link>
    );
  }
  return result;
};
