import styled from "styled-components";

export const CliContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
`;

export const CliCommand = styled.input`
  color: ${(p) => p.theme.color.white};
  border: 0;
  font-size: 1.1em;
  background-color: transparent;
  padding: 0;
  outline: none;
  width: 100%;
  word-wrap: anywhere;
`;

export const CliUser = styled.div`
  color: ${(p) => p.theme.color.primary};
  display: inline-block;
  font-size: 1.2em;
`;

export const CliResult = styled.div`
  color: ${(p) => p.theme.color.gray};
  margin: 0;
  padding: 0;
  border: none;
  font-size: 1.1em;
  word-wrap: anywhere;
`;
