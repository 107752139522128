import React, { useRef, useState, useEffect, Fragment } from "react";
import { Result } from "../Result";
import { CliContainer, CliCommand, CliResult, CliUser } from "./styles";
import { Command } from "./types";

export const Cli = () => {
  const commandInput = useRef<HTMLInputElement>(null);
  const [command, setCommand] = useState<string>("");
  const [results, setResults] = useState<Command[]>([]);
  const [files, setFiles] = useState<string[]>(["about.txt", "contact.txt"]);

  useEffect(() => {
    commandInput?.current?.focus();
  }, []);

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [command]);

  const commandHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommand(e.target.value);
  };

  const commands: Command[] = [
    { command: "help", results: ["ls, touch, cat, whoami, hi, date, clear"] },
    { command: "ls", results: files },
    { command: "ll", results: files },
    {
      command: "cat",
      results: (command) => {
        const [, param] = command.split(" ");
        if (param === "about.txt") {
          setResults([...results, { command, results: ["I'm Siamak. A javascript developer :)"] }]);
        } else if (param === "contact.txt") {
          setResults([
            ...results,
            {
              command,
              results: [
                "Email:",
                "siamak.aghaeipour@gmail.com",
                "Linkedin:",
                "http://www.linkedin.com/in/siamaka",
                "Stackoverflow:",
                "https://stackoverflow.com/users/944948/siamak-a-motlagh?tab=profile",
              ],
            },
          ]);
        } else {
          if (!files.includes(param)) {
            setResults([
              ...results,
              {
                command,
                results: [`File does not exist: "${param || ""}"`],
              },
            ]);
          } else {
            setResults([
              ...results,
              {
                command,
                results: [""],
              },
            ]);
          }
        }
      },
    },
    { command: "whoami", results: ["You are awesome :)"] },
    { command: "hi", results: ["Hi to you ;)"] },
    { command: "date", results: [new Date().toISOString()] },
    {
      command: "touch",
      results: (command) => {
        const [, param] = command.split(" ");
        if (param) {
          if (files.includes(param)) {
            setResults([
              ...results,
              {
                command,
                results: [`File "${param}" does exist.`],
              },
            ]);
          } else {
            setFiles([...files, param]);
            setResults([
              ...results,
              {
                command,
                results: [`File "${param}" has been created.`],
              },
            ]);
          }
        } else {
          setResults([
            ...results,
            {
              command,
              results: [`File name is invalid.`],
            },
          ]);
        }
      },
    },
    {
      command: "clear",
      results: () => {
        setResults([]);
      },
    },
  ];

  const executeCommand = (command: string) => {
    const commandObject = commands.find((c: Command) => command.trim().includes(c.command));

    if (commandObject) {
      if (commandObject.results instanceof Function) {
        commandObject.results(command);
      } else {
        setResults([...results, commandObject]);
      }
    } else {
      setResults([...results, { command, results: [`command not found: ${command}`] }]);
    }
  };

  const commitCommand = (e: any) => {
    if (e.key === "Enter") {
      executeCommand(command);
      setCommand("");
    }
  };

  const User = () => <CliUser>root@blacksrc:~$</CliUser>;

  return (
    <>
      {results.map(({ command, results }: Command, i: number) => {
        return (
          <Fragment key={i}>
            <CliContainer>
              <User />
              <CliCommand value={command} readOnly />
            </CliContainer>
            {(results as string[]).map((result: string, index: number) => (
              <CliResult key={index}>
                <Result result={result} />
              </CliResult>
            ))}
          </Fragment>
        );
      })}
      <CliContainer>
        <User /> <CliCommand ref={commandInput} value={command} onChange={commandHandler} onKeyDown={commitCommand} />
      </CliContainer>
    </>
  );
};
